// @FIXME - this variables should be deleted and nexus theme should be used instead
import { GENERICS } from '@lib/styles/partials/colors';

import { SEARCH_WIDGET_THEME as SearchWidget } from './otodompl';
import type { SearchWidgetVariables } from './type';

export const SEARCH_WIDGET_THEME: SearchWidgetVariables = {
    ...SearchWidget,
    search: {
        ...SearchWidget.search,
        backgroundImage: {
            gradient: {
                background: `linear-gradient(to right, ${GENERICS.black}80, ${GENERICS.black}80)`,
            },
        },
    },
};
